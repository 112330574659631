<template>
  <div>
    <DxDataGrid
      :data-source="dataSource"
      :show-borders="true"
      :column-auto-width="true"
    >
      <DxColumn data-field="id" caption="Id" />
      <DxColumn data-field="dcardCode" caption="Stok Kodu" />
      <DxColumn data-field="stockName" caption="Stok Adı" />
      <DxColumn data-field="unitCode" caption="Birim" />
      <DxColumn data-field="dxqty" alignment="right" caption="Miktarı" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="unitPriceTra" alignment="right" caption="Birim Fiyat" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="dxamtTra" alignment="right" caption="Hareket Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="curTraCode" caption="Para Birimi" />
      <DxColumn data-field="curRateTra" alignment="right" caption="Kur" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="amt" alignment="right" caption="Yerel Para Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="dxamtWithDisc" alignment="right" caption="İskonto Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="vatRate" alignment="right" caption="Kdv Yüzdesi" :customize-text="customizeDecimalDxGrid" />
      <DxColumn data-field="dxamtVat" alignment="right" caption="Kdv Tutarı" :customize-text="customizeDecimalDxGrid" />
      <DxSummary>
        <DxTotalItem
          column="dxqty"
          summary-type="sum"
          display-format="{0}"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxTotalItem
          column="dxamtTra"
          summary-type="sum"
          display-format="{0}"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxTotalItem
          column="dxamtWithDisc"
          summary-type="sum"
          display-format="{0}"
          :customize-text="customizeDecimalDxGrid"
        />
        <DxTotalItem
          column="dxamtVat"
          summary-type="sum"
          display-format="{0}"
          :customize-text="customizeDecimalDxGrid"
        />
      </DxSummary>
    </DxDataGrid>
  </div>
</template>
<script>
import { DxDataGrid, DxColumn, DxSummary, DxTotalItem } from 'devextreme-vue/data-grid';
import { customizeDecimalDxGrid } from '@core/utils/filter';
import { locale } from 'devextreme/localization';

export default {
  components: { DxDataGrid, DxColumn, DxSummary, DxTotalItem },
  props: {
    teklifDetayData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      dataSource: this.teklifDetayData.data.uyumDetailItem,
    }
  },
  methods: {
    customizeDecimalDxGrid,
  },
  created() {
    locale('tr')
  },
};
</script>

<style>
.master-detail-caption {
  padding: 0 0 5px 10px;
  font-size: 14px;
  font-weight: bold;
}
</style>
