<template>
  <v-select
    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
    :value="teklifDurumSelect"
    :options="selectionTeklifDurumList"
    class="w-100"
    :reduce="val => val.value"
    @input="(val) => $emit('update:teklifDurumSelect', val)" />
</template>

<script>
import StaticOptions from '@/common/options/StaticOptions';

export default {
  props: {
    teklifDurumSelect: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      selectionTeklifDurumList: StaticOptions.teklifDurum,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
