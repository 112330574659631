<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <b-form-group
            :label="$t('Belge Tarihi Başlangıç')"
            label-for="dtBaslangicFilter"
          >
            <b-form-input
              type="date"
              v-model="dtBaslangicFilter"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group
            :label="$t('Belge Tarihi Bitiş')"
            label-for="dtBitisFilter"
          >
            <b-form-input type="date" v-model="dtBitisFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="" label-for="">
            <label for="entityIdFilter">
              {{ $t("Müşteri Seçiniz") }}
            </label>
            <musteri-selection 
              :is-uyum-entity-id="true"
              :musteri-select.sync="entityIdFilter" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3">
          <b-form-group label="" label-for="">
            <label for="teklifDurumuFilter">
              {{ $t("Durum Seçiniz") }}
            </label>
            <teklif-durum-selection
              :teklif-durum-select.sync="teklifDurumuFilter"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import MusteriSelection from '../selection/MusteriSelection.vue';
import TeklifDurumSelection from '../selection/TeklifDurumSelection.vue';

export default {
  components: {
    MusteriSelection,
    TeklifDurumSelection,
  },
  computed: {
    dtBaslangicFilter: {
      get() {
        return this.$store.getters['teklifYonetim/getTeklifFilters'].dtBaslangic
      },
      set(value) {
        this.$store.commit('teklifYonetim/setTeklifFilter', { key: 'dtBaslangic', value })
      },
    },
    dtBitisFilter: {
      get() {
        return this.$store.getters['teklifYonetim/getTeklifFilters'].dtBitis
      },
      set(value) {
        this.$store.commit('teklifYonetim/setTeklifFilter', { key: 'dtBitis', value })
      },
    },
    entityIdFilter: {
      get() {
        return this.$store.getters['teklifYonetim/getTeklifFilters'].entityId
      },
      set(value) {
        this.$store.commit('teklifYonetim/setTeklifFilter', { key: 'entityId', value })
      },
    },
    teklifDurumuFilter: {
      get() {
        return this.$store.getters['teklifYonetim/getTeklifFilters'].teklifDurumu
      },
      set(value) {
        this.$store.commit('teklifYonetim/setTeklifFilter', { key: 'teklifDurumu', value })
      },
    },
  },
}
</script>
